@use "custom" as *;
@use "fonts" as *;
@use "flex-layout" as *;

body, html {
    margin: 0;
    padding: 0;
    /*background: linear-gradient(170deg,#463142 10%,#bf7e65 100%);
    background: linear-gradient(170deg,#46203f 10%,#c76049 100%);
    background: linear-gradient(170deg,#444244 7%,#c5624c 100%);
    background: linear-gradient(170deg,#200b20 7%,#320606 100%);
    background: linear-gradient(170deg,#444244 7%,#a77c6c 100%);
    background: linear-gradient(170deg,#2a122a 7%,#a77c6c 100%);
    background-color: #7e5e7e;
    background-color:#7f8cc1;
    background-color: #949dc3;
    background-color: #8c95b9;
    background-color: #8e8dc3;*/
    background-color: #8b8abb;
    
    background-attachment: fixed;
}

.mat-button, .mat-raised-button {
    border-radius: 0;
}

.mat-simple-snackbar {
    font-weight: bold;
  }

.mat-simple-snackbar span.mat-button-wrapper {
    color: white;
    font-weight: bold;
}

.mat-snack-bar-container .mat-simple-snackbar {
  color: white;
}
  
.mat-snack-bar-container.green .mat-simple-snackbar {
    color: #3bc177;
  }
  
.mat-snack-bar-container.red .mat-simple-snackbar {
    color: var(--warn300);
  }

  .card-wrapper {
    padding: 0 8px;
    box-sizing: border-box;
  }

  .pole {
    width: 0;
    height: calc(100vh - 58px);
    height: calc(100dvh - 47px);
  }

  .sticky {
    top: 86px;
  }

.date-setting {
  margin-left: 12px;
}

.mat-chip.mat-standard-chip {
  background-color: white;
  border: 2px solid var(--accent200);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #fff9 !important;
}

.mat-badge-accent .mat-badge-content {
  background: var(--accenta700);
  font-weight: 700;
}

mat-label {
  font-size: 17px !important;
  font-weight: 600 !important;
}

mat-label .mat-icon {
  height: 20px !important;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    background-color: var(--accent900);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-17px) scale(.75) perspective(100px) translateZ(0.001px);
}

  @media only screen and (min-width: 600px) {
    .card-wrapper {
      padding: 0 12px;
    }

    .date-setting {
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 960px) {
    .card-wrapper {
      padding: 0 12px 12px;
    }
  }

  @media only screen and (min-width: 1280px) {
    *::-webkit-scrollbar,
    *::-webkit-scrollbar-thumb {
      width: 14px;
      border-radius: 9px;
      background-clip: padding-box;
      border: 4px solid transparent;
    }
    
    *::-webkit-scrollbar-thumb {        
      box-shadow: inset #42352c 0 0 0 6px;
    }
/*
    .sticky {
      top: 84px;
    }
 */   
    .content-wrapper {
    scrollbar-color: #42352c transparent;
    }

    mat-sidenav-content {
        scrollbar-color: #42352c transparent;
    }

    .date-setting {
      margin-left: 12px;
    }
  }