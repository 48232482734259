@import 'node_modules/@angular/material/theming';

$mat-myapp-primary: (
    50 : #ebebf2,
    100 : #cecee0,
    200 : #adadcb,
    300 : #8c8cb6,
    400 : #7473a6,
    500 : #5b5a96,
    600 : #53528e,
    700 : #494883,
    800 : #403f79,
    900 : #2f2e68,
    A100 : #b3b2ff,
    A200 : #817fff,
    A400 : #4f4cff,
    A700 : #3632ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-myapp-accent: (
    50 : #e2e2e2,
    100 : #b8b8b8,
    200 : #888888,
    300 : #585858,
    400 : #353535,
    500 : #111111,
    600 : #0f0f0f,
    700 : #0c0c0c,
    800 : #0a0a0a,
    900 : #050505,
    A100 : #ff4e4e,
    A200 : #ff1b1b,
    A400 : #e70000,
    A700 : #ce0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-myapp-warn: (
    50 : #ffe6e0,
    100 : #ffc0b3,
    200 : #ff9680,
    300 : #ff6c4d,
    400 : #ff4d26,
    500 : #ff2d00,
    600 : #ff2800,
    700 : #ff2200,
    800 : #ff1c00,
    900 : #ff1100,
    A100 : #ffffff,
    A200 : #fff3f2,
    A400 : #ffc1bf,
    A700 : #ffa8a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-myapp-bonus: (
    50 : #fcf7ef,
    100 : #f7ebd6,
    200 : #f1debb,
    300 : #ebd09f,
    400 : #e7c68b,
    500 : #e3bc76,
    600 : #e0b66e,
    700 : #dcad63,
    800 : #d8a559,
    900 : #d09746,
    A100 : #ffffff,
    A200 : #fffaf3,
    A400 : #ffe4c0,
    A700 : #ffd9a7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: mat-palette($mat-myapp-primary);
$accent: mat-palette($mat-myapp-accent);
$warn: mat-palette($mat-myapp-warn);
$bonus: mat-palette($mat-myapp-bonus);

$theme: mat-light-theme($primary, $accent, $warn);

:root {
    --primary: #5b5a96;
    --primary50: #ebebf2;
    --primary100: #cecee0;
    --primary200: #adadcb;
    --primary300: #8c8cb6;
    --primary400: #7473a6;
    --primary500: #5b5a96;
    --primary600: #53528e;
    --primary700: #494883;
    --primary800: #403f79;
    --primary900: #2f2e68;

    --accent: #111111;
    --accent50: #e2e2e2;
    --accent100: #b8b8b8;
    --accent200: #888888;
    --accent300: #585858;
    --accent400: #353535;
    --accent500: #111111;
    --accent600: #0f0f0f;
    --accent700: #0c0c0c;
    --accent800: #0a0a0a;
    --accent900: #050505;
    --accenta400: #e70000;
    --accenta700: #ce0000;

    --warn: #ff2d00;
    --warn50: #ffe6e0;
    --warn100: #ffc0b3;
    --warn200: #ff9680;
    --warn300: #ff6c4d;
    --warn400: #ff4d26;
    --warn500: #ff2d00;
    --warn600: #ff2800;
    --warn700: #ff2200;
    --warn800: #ff1c00;
    --warn900: #ff1100;
    
    --bonus: #e3bc76;
    --bonus50: #fcf7ef;
    --bonus100: #f7ebd6;
    --bonus200:  #f1debb;
    --bonus300: #ebd09f;
    --bonus400: #e7c68b;
    --bonus500: #e3bc76;
    --bonus600: #e0b66e;
    --bonus700: #dcad63;
    --bonus800: #d8a559;
    --bonus900: #d09746;

    --grey400: #bdbdbd;
 }

@include angular-material-theme($theme);

$custom-typography: mat-typography-config($font-family: '"Nunito", sans-serif;');
@include mat-core($custom-typography);