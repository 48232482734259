.flex {
    display: flex !important;
}

.fx-layout-row {
    display: flex;
    flex-direction: row;
}

.fx-layout-column {
    display: flex;
    flex-direction: column !important;
}

.fx-layout-wrap {
    flex-wrap: wrap;
}

.fx-layout-nowrap {
  flex-wrap: nowrap;
}

.none {
    place-content: flex-start;
    align-items: flex-start;
}

.start {
    justify-content: start;
}

.flex-start {
    align-items: flex-start;
    align-content: flex-start;
}

.space-between {
  place-content: stretch space-between;
  align-items: stretch;
}

.space-between-center {
  place-content: center space-between;
  align-items: center;
}

.space-between-end {
    place-content: flex-end space-between;
    align-items: flex-end;
}

.space-evenly {
  place-content: stretch space-evenly;
  align-items: stretch;
}

.space-evenly-start {
  place-content: flex-start space-evenly;
  align-items: flex-start;
}

.space-around {
  place-content: stretch space-around;
  align-items: stretch;
}

.fx-align-center {
  place-content: center center;
  align-items: center;
  justify-content: center;
}

.fx-align-stretch-center {
  place-content: stretch center;
  align-items: stretch;
}

.center {
  justify-content: center;
}

.center-start {
  place-content: flex-start center;
  align-items: flex-start;
}

.fx-100 {
    width: 100%;
    box-sizing: border-box;
}

.gap-0 {
    gap: 0;
}

.gap-8 { 
  gap: 8px;
}

.gap-16 { 
  gap: 16px;
}

.gap-20 { 
  gap: 20px;
}

.gap-64 { 
  gap: 64px;
}

.gap-1pc {
  gap: 1%
}

.gap-3pc {
  gap: 3%
}

.gap-4pc {
  gap: 4%
}

.fx-hide {
    display: none !important;
  }

.fx-1-1-44 {
  flex: 1 1 44%;
  box-sizing: border-box;
  max-width: 44%;
}

.fx-48 {
  flex: 1 1 48%;
  box-sizing: border-box;
}

.fx-48-gt-xs {
  width: 100%;
  box-sizing: border-box;
}

.fx-1-1-50-gt-sm {
  width: 100%;
}


@media only screen and (max-width: 599px) {
    .fx-hide-xs {
      display: none !important;
    }

    .fx-layout-row-xs {
      display: flex;
      flex-direction: row !important;
  }

    .fx-layout-column-xs {
      display: flex;
      flex-direction: column;
    }

    .center-xs {
      justify-content: center;
    }

    .fx-100-xs {
      width: 100%;
      box-sizing: border-box;
  }

    .fx-1-1-50-xs {
      flex: 1 1 100%;
      box-sizing: border-box;
      max-width: 50%;
    }

    .fx-hide {
      display: none !important;
    }
  }

@media only screen and (min-width: 600px) {
  .fx-show-xs {
    display: none !important;
  }

  .fx-layout-column-gt-xs {
    display: flex;
    flex-direction: column;
  }

  .fx-layout-row-gt-xs {
    display: flex !important;
    flex-direction: row;
  }

  .fx-layout-wrap-gt-xs {
    flex-wrap: wrap;
  }

  .fx-48-gt-xs {
    flex: 1 1 48%;
    max-width: 48%;
  }

  .fx-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
    .fx-hide-sm {
      display: none !important;
    }
  }

@media only screen and (min-width: 960px) {
  .fx-hide-gt-sm {
    display: none !important;
  }

  .fx-1-1-50-gt-sm {
    flex: 1 1 44%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
    .fx-hide-md {
        display: none !important;
    }
  }

@media only screen and (max-width: 959px) {
    .fx-hide-lt-md {
      display: none !important;
    }

    .space-between-center-lt-md {
      place-content: center space-between;
      align-items: center;
    }
  }

@media only screen and (max-width: 1279px) {
    .fx-hide-lt-lg {
      display: none !important;
    }
  }

@media only screen and (min-width: 1280px) {
    .fx-hide-gt-md {
      display: none !important;
    }

    .gap-64-gt-md { 
      gap: 64px;
    }
}